import * as yup from "yup";

export const getCashierValidationSchema = () => {
    return yup.object({
        name: yup
            .string()
            .required('Введите Имя'),
        login: yup
            .string()
            .required('Введите Логин'),
        pin: yup
            .number()
            .required('Введите Пин'),
        active: yup
            .boolean()
            .required('Выберите Статус'),
        password: yup
            .string()
            .required('Введите Пароль'),
        licenseKey: yup
            .string()
            .required('Введите Ключ Лицензии'),
        terminal: yup
            .string()
            .required('Введите Терминал'),
        dailyLimit: yup
            .number()
            .min(0, 'Дневной Лимит не может быть меньше 0')
            .required('Введите Дневной Лимит'),
    });
}