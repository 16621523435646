import {
    deleteGoodsRestRestriction,
    GoodsRestRestrictionSearchCriteria,
    searchGoodsRestRestrictions
} from "../../service/goods_rest_restriction";
import {OrderBy} from "../../types";
import {useCallback, useEffect, useState} from "react";
import {searchOutlets} from "../../service/outlets";
import {getAxiosCancellationFunction} from "../../utils/api";
import {Outlet} from "../../types/outlet";
import {PagedSearchResult} from "../../types/pagination";
import {GoodsRestRestriction} from "../../types/goodsrestrestriction/intex";
import usePreloader from "../../hooks/usePreloader";

export function useGoodsRestRestrictions(filters: GoodsRestRestrictionSearchCriteria, orderBy: OrderBy) {
    const [error, setError] = useState<Error | undefined>(undefined);
    const [version, setVersion] = useState<number>(0);
    const {pageNumber, rowsPerPage} = filters;
    const [outlets, setOutlets] = useState<Outlet[] | undefined>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<PagedSearchResult<GoodsRestRestriction>>({
        pageNumber,
        pageSize: rowsPerPage,
        results: [],
        totalPages: 1,
        totalResults: 0
    });

    usePreloader(loading);


    useEffect(() => {
        let mounted = true;
        const abCtrl = new AbortController();

        searchOutlets({onlyActive: true}, abCtrl)
            .then(value => {
                if (mounted) setOutlets(value);
            }).catch(error => {
            if (mounted) setError(error);
        })

        return getAxiosCancellationFunction(mounted, abCtrl)

    }, []);

    const deleteGoogsRestRestriction = useCallback((restriction: GoodsRestRestriction) => {
        setLoading(true);

        deleteGoodsRestRestriction(restriction)
            .then(r => setVersion(version + 1))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }, [setVersion, version]);

    useEffect(() => {
        let mounted = true;
        const abCtrl = new AbortController();
        setLoading(true);

        searchGoodsRestRestrictions(filters, orderBy, abCtrl)
            .then(value => {
                if (mounted) setPage(value);
            }).catch(error => {
            if (mounted) setError(error);
        }).finally(() => {
            if (mounted) setLoading(false)
        })

        return getAxiosCancellationFunction(mounted, abCtrl);
    }, [version, filters, orderBy])


    return {
        loading,
        error,
        reload: useCallback(() => setVersion(version+1), [setVersion, version]),
        outlets: outlets,
        clearError: useCallback(() => setError(undefined), [setError]),
        deleteRestriction: deleteGoogsRestRestriction,
        page
    }
}