import React from "react";
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";

const NothingFound = () => {
    return <Paper sx={{p: 2, mb: 1}}>
        <Typography>Ничего не найдено</Typography>
    </Paper>
};

export default NothingFound;