import axios, {CreateAxiosDefaults} from 'axios'
import Cookies from 'universal-cookie';
import {UrlInfo} from "./UrlInfo";

const urlInfo = new UrlInfo(window.location.href);

function getBaseUrl() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `${urlInfo.getScheme()}://${urlInfo.getDomine()}:${urlInfo.getPort()}/api`;
    }

    return `${urlInfo.getScheme()}://${urlInfo.getDomine()}/api`;
}

const cookies = new Cookies();

export const axiosInstance = axios.create({
        baseURL: getBaseUrl(),
        timeout: 30000,
    } as CreateAxiosDefaults
);

if (!!cookies.get('token')) {
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + cookies.get('token');
}

export const setAuthorization = (token: string) => {
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (axios.isCancel(error))
        return Promise.resolve();

    if (error.response.status === 401) {
        // return Promise.reject('Пожалуйста войдите в систему');
    }
    return Promise.reject(error);
});

export function getAxiosCancellationFunction(mounted: boolean, abortController: AbortController) {
    return function () {
        mounted = false;
        abortController.abort();
    }
}