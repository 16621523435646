import React, {FormEventHandler} from "react";
import {FormControlLabel, Switch} from "@mui/material";

type OwnProps = {
    value?: boolean,
    onChange: FormEventHandler<HTMLInputElement>
}

export const FilterActive = ({value, onChange}: OwnProps) => {
    return <FormControlLabel
        labelPlacement="start"
        control={
            <Switch
                checked={value !== undefined && value}
                onChange={onChange}
                name="active"
                color="secondary"
            />
        }
        label="Только Активные"
    />
};