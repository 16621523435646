import {useCallback, useState} from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {AddRestrictionDialog} from "./AddRestrictionDialog";
import isEmpty from "lodash/isEmpty";
import {Outlet} from "../../../types/outlet";

type OwnProps = {
    outlets?: Outlet[]
    onClose: () => void
}

export const AddRestriction =  ({onClose, outlets}: OwnProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    const handleClose = useCallback(() => {
        setOpen(false);
        onClose();
    }, [setOpen, onClose]);

    return <>
        <IconButton onClick={handleOpen} size={"medium"} disabled={isEmpty(outlets)}>
            <AddIcon fontSize="inherit" color={"secondary"}/>
        </IconButton>
        <AddRestrictionDialog open={open} outlets={outlets} onClose={handleClose}/>
    </>
}