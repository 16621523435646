import * as yup from "yup";

export const getGoodsRestRestrictionValidationSchema = () => {
    return yup.object({
        chmsoftGoodsId: yup
            .number()
            .required('Выберите товар'),
        chmsoftGoodsName: yup
            .string()
            .required('Выберите товар'),
        minQuantity: yup
            .number()
            .min(0)
            .required('Введите минимальное колличество'),
        chmsoftOutletId: yup
            .number()
            .required('Выберите магазин'),
    });
}