import React, {memo, useCallback, useState} from "react";
import ReceiptIcon from '@mui/icons-material/Receipt';
import {PostedChecksSearchCriteria} from "../../service/checks";
import {usePostedChecks} from "./usePostedChecks";
import Page from "../template/Page";
import Filters from "../template/Filters";
import {
    Fab,
    MenuItem,
    Paper,
    Stack,
    TablePagination,
    TextField, Typography
} from "@mui/material";
import SortedTableCell from "../../components/table/SortedTableCell/SortedTableCell";
import FlagIcon from '@mui/icons-material/Flag';
import {sxPageTable} from "../../constants/style";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import isEmpty from "lodash/isEmpty";
import ErrorAlert from "../../components/ErrorAlert";
import {errorMessage} from "../../constants/text";
import CachedIcon from '@mui/icons-material/Cached';
import map from "lodash/map";
import reduce from "lodash/reduce";
import {PostedCheck} from "../../types/postedcheck";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DateTime} from "luxon";
import {Dictionary, OrderBy} from "../../types";
import DeleteCheck from "./DeleteCheck";
import {CashiersSearchCriteria} from "../../service/cashiers";
import {useCashiers} from "../Cashiers/useCashiers";
import Content from "../template/content/Content";
import CheckStatus from "./CheckStatus";
import {NoDataFoundTableRow} from "../../components/NoDataFoundTableRow";
import {useExciseCashiers} from "../ExciseCashiers/useExciseCashiers";

const checkboxCashiersCriteria: CashiersSearchCriteria = {onlyActive: false};

const PostedChecks = () => {
    const defaultFilters: PostedChecksSearchCriteria = {
        pageNumber: 1,
        rowsPerPage: 25,
        since: DateTime.now().minus({day: 2}),
        till: DateTime.now()
    };

    const {cashiers} = useCashiers(checkboxCashiersCriteria);
    const {cashiers: exciseCashiers} = useExciseCashiers(checkboxCashiersCriteria);
    const [filters, setFilters] = useState<PostedChecksSearchCriteria>(defaultFilters)
    const [orderBy, setOrderBy] = useState<OrderBy>({
        field: 'createDate', direction: 'DESC'
    });
    const {error, page, reload, deleteCheck, clearError} = usePostedChecks(filters, orderBy);

    const handleChangePage = useCallback((event: unknown, newPage: number) => {
        setFilters({...filters, pageNumber: newPage + 1})
    }, [filters]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({...filters, pageNumber: 1, rowsPerPage: parseInt(event.target.value, 10)})
    };

    const handleSelectFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({...filters, [event.target.name]: event.target.value as any, pageNumber: 1})
    }, [filters, setFilters])

    const getCashierName = useCallback((cashierId: number) => {
        let namePerId = reduce(cashiers, (acc: Dictionary<string>, cashier) => {
            acc[cashier.id] = cashier.name;
            return acc;
        }, {});

        namePerId = reduce(exciseCashiers, (acc: Dictionary<string>, cashier) => {
            acc[cashier.id] = cashier.name;
            return acc;
        }, namePerId);

        return isEmpty(namePerId[cashierId]) ? '' : namePerId[cashierId];
    }, [cashiers, exciseCashiers])

    return <Page title="Чеки" icon={<ReceiptIcon/>}>
        <Stack direction="column" spacing={2}>
            <Filters>
                <DatePicker
                    inputFormat={'MMM dd, yyyy'}
                    label="Начальная Дата"
                    value={filters.since}
                    onChange={(newValue) => {
                        setFilters({...filters, since: newValue as DateTime, pageNumber: 1});
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                    inputFormat={'MMM dd, yyyy'}
                    label="Конечная Дата"
                    value={filters.till}
                    onChange={(newValue) => {
                        setFilters({...filters, till: newValue as DateTime, pageNumber: 1});
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                <TextField select value={filters.status || ''}
                           name="status"
                           onChange={handleSelectFilterChange}
                           fullWidth sx={{minWidth: '17rem'}}
                           label="Статус">
                    <MenuItem value={''}>&nbsp;</MenuItem>
                    <MenuItem value={'SUCCESS'}>SUCCESS</MenuItem>
                    <MenuItem value={'UNKNOWN'}>UNKNOWN</MenuItem>
                    <MenuItem value={'ERROR'}>ERROR</MenuItem>
                </TextField>
                <TextField select value={filters.cashierId || ''}
                           name="cashierId"
                           onChange={handleSelectFilterChange}
                           fullWidth sx={{minWidth: '17rem'}}
                           label="Кассир">
                    <MenuItem value={''}>&nbsp;</MenuItem>
                    {map(cashiers, (cashier) => (
                        <MenuItem key={cashier.id} value={cashier.id}>{cashier.name}</MenuItem>))}
                </TextField>
                <Fab aria-label="обновить"
                     onClick={reload}
                     title={"Обновить"}
                     size={"medium"}
                     color={"info"}>
                    <CachedIcon/>
                </Fab>

            </Filters>
            {!!error && <ErrorAlert onClose={clearError}>
                {error?.message || errorMessage.defaultMessage}
            </ErrorAlert>}

            <Content>
                <TableContainer component={Paper}>
                    <Table sx={sxPageTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Хамелион ID</TableCell>
                                <SortedTableCell
                                    field={"createDate"}
                                    orderBy={orderBy}
                                    onChange={setOrderBy}>
                                    Дата
                                </SortedTableCell>
                                <TableCell>Кассир</TableCell>
                                <TableCell>Статус</TableCell>
                                <TableCell>Сумма</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(isEmpty(page) || isEmpty(page.results)) && <NoDataFoundTableRow colSpan={6}/>}
                            {!isEmpty(page) && map(page.results, (check: PostedCheck) => {
                                return <TableRow key={check.chmsoftCheckId} sx={{backgroundColor: check.needsResend ? '#C8DBC834' : 'inherit'}}>
                                    <TableCell>
                                        {check.excise ? (
                                            <Typography title={'Акцизных чеков нет в базе Хамелиона. Они создаются динамически из товаров на остатках.'}>
                                                Акциз
                                            </Typography>
                                        ): (<ChmsoftCheck check={check}/>)}

                                    </TableCell>
                                    <TableCell>{DateTime.fromISO(check.createDate).toFormat('MMMM dd, yyyy HH:mm:ss')}</TableCell>
                                    <TableCell>{getCashierName(check.checkboxCashierId)}</TableCell>
                                    <TableCell><CheckStatus check={check}/></TableCell>
                                    <TableCell>{check.sum}</TableCell>
                                    <TableCell align={"right"}>
                                        <DeleteCheck check={check} onDelete={deleteCheck}/>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Content>

            <Paper>
                <TablePagination
                    labelRowsPerPage={"Чеков на странице"}
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500, 1000]}
                    component="div"
                    count={page.totalResults}
                    rowsPerPage={filters.rowsPerPage}
                    page={filters.pageNumber - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
            </Paper>
        </Stack>
    </Page>
}

const ChmsoftCheck = memo<{check: PostedCheck}>(({check}) => {
    if (check.needsResend) {
        return  <Stack spacing={2} direction={"row"} alignItems={"center"} title={"Этот чек будет повторно отправлен в Чекбокс при следующей синхронизации"}>
            <div>{check.chmsoftCheckId}</div>
            <FlagIcon sx={{color: 'green'}}/>
        </Stack>
    }

    return <>{check.chmsoftCheckId}</>
})



export default PostedChecks;