import React, {useCallback, useState} from "react";
import {TextField} from "@mui/material";

type OwnProps = {
    keyword: string
    onChange: (keyword?: string) => void
}

export const KeywordFilter: React.FC<OwnProps> = ({keyword, onChange}) => {
    const [value, setValue] = useState(keyword);

    const handleBlur = useCallback((_: any) => {
        if (value !== keyword) {
            onChange(value);
        }
    }, [keyword, value, onChange]);

    const handleChange = useCallback((event: any) => {
        setValue(event.target.value)
    }, [setValue]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && value !== keyword) {
            onChange(value);
        }
    }, [keyword, value, onChange]);

    return <TextField value={value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyDown}
                      label={"Поиск"}
                      fullWidth sx={{minWidth: '17rem', maxWidth: '18rem'}}
                      name={"keyword"}/>;
}