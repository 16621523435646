import {axiosInstance} from "../utils/api";
import {Dictionary} from "../types";
import reduce from "lodash/reduce";

export interface Property {
    key: string,
    value: string
}

export const getProperties = async (controller: AbortController): Promise<Dictionary<string>> => {
    const response = await axiosInstance.get('/checkbox/settings/all', {
        signal: controller.signal
    });

    return reduce(response.data as Property[], (acc: Dictionary<string>, property: Property) => {
        acc[property.key] = property.value;
        return acc;
    }, {});
}

export const saveProperty = async (key: string, value?: string) : Promise<unknown> => {
    let url = '/checkbox/settings?key=' + encodeURIComponent(key);
    if (!!value) {
        url += '&value=' + encodeURIComponent(value);
    }

    return await axiosInstance.post(url);
}

export const saveProperties = async (properties: Partial<Property>[]) : Promise<unknown> => {
    let url = '/checkbox/settings/all';

    return await axiosInstance.post(url, properties);
}