import React, {SyntheticEvent} from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import Paper from "@mui/material/Paper";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import IosShareIcon from '@mui/icons-material/IosShare';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SendIcon from '@mui/icons-material/Send';
import Page from "../template/Page";
import {settings} from "../../constants/settings";
import {Container} from "@mui/material";
import {useProperties} from "./useProperties";
import usePreloader from "../../hooks/usePreloader";
import toString from "lodash/toString";
import ErrorAlert from "../../components/ErrorAlert";
import {errorMessage} from "../../constants/text";

const styles = {
    list: {width: '100%', bgcolor: 'background.paper'},
    input: {width: "50%"},
}

export default function Settings() {
    const {error, loading, properties, clearError, saveProperty} = useProperties()
    usePreloader(loading);

    const setSwitchProperty = (event: SyntheticEvent<HTMLInputElement>, newValue: boolean) => {
        saveProperty(event.currentTarget.name, toString(newValue))
    }

    const isChecked = (name: string) => {
        if ('true' === properties[name]) {
            return true;
        } else if ('false' === properties[name]) {
            return false;
        } else {
            return !!properties[name];
        }
    };


    return <Page title={"Настройки"} icon={<SettingsIcon/>}>
        <Container maxWidth={"md"}>
            <Paper sx={{padding: '1rem'}}>
                {!!error && <ErrorAlert onClose={clearError}>
                    {error?.message || errorMessage.defaultMessage}
                </ErrorAlert>}

                <List sx={styles.list}>
                    <ListItem>
                        <ListItemIcon>
                            <IosShareIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Отправка чеков"
                                      secondary={"Включение/Отключение отправки чеков в Checkbox"}/>
                        <Switch color={"secondary"}
                                name={settings.sendingChecksEnabled}
                                onChange={setSwitchProperty}
                                checked={isChecked(settings.sendingChecksEnabled)}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <IosShareIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Отправка чеков АКЦИЗ"
                                      secondary={"Включение/Отключение отправки акцизных чеков в Checkbox"}/>
                        <Switch color={"secondary"}
                                name={settings.sendingExciseChecksEnabled}
                                onChange={setSwitchProperty}
                                checked={isChecked(settings.sendingExciseChecksEnabled)}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SendIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Оповещение о переоценке"
                                      secondary={"Включение/Отключение оповещения о переоценке товаров через Телеграм"}/>
                        <Switch color={"secondary"}
                                name={settings.repriceNotificationEnabled}
                                onChange={setSwitchProperty}
                                checked={isChecked(settings.repriceNotificationEnabled)}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PriorityHighIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Оповещение о заканчивающемся товаре"
                                      secondary={"Включение/Отключение оповещения через Телеграм о том, что товар на остатках заканчивается"}/>
                        <Switch color={"secondary"}
                                name={settings.goodsQuantityIsLowNotificationEnabled}
                                onChange={setSwitchProperty}
                                checked={isChecked(settings.goodsQuantityIsLowNotificationEnabled)}/>
                    </ListItem>
                </List>
            </Paper>
        </Container>
    </Page>
};