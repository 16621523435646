import {memo, ReactNode} from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";

type OwnProps = {
    open: boolean,
    onAccept: () => void,
    onReject: () => void,
    children: ReactNode

}

const ConfirmDialog = memo<OwnProps>((props) => {
    const {open, onAccept, onReject, children} = props;

    return <Dialog open={open}
        onClose={onReject}>
        <DialogContent>
            {children}
        </DialogContent>
        <DialogActions>
            <Button onClick={onAccept} autoFocus>
                Ок
            </Button>
            <Button onClick={onReject}>Отмена</Button>
        </DialogActions>
    </Dialog>
});

export default ConfirmDialog;