import {axiosInstance} from "../utils/api";
import {Outlet} from "../types/outlet";
import axios from 'axios';

export interface OutletsSearchCriteria {
    onlyActive?: boolean;
}

export const searchOutlets = async (filters: OutletsSearchCriteria, controller: AbortController): Promise<Outlet[]> => {
    let url = '/outlets/search';
    if (filters.onlyActive !== undefined) {
        url += '?onlyActive=' + filters.onlyActive;
    }

    try {
        const response = await axiosInstance.get(url, {
            signal: controller.signal
        });

        let res: Outlet[] = [];
        if (!!response && response.data) {
            res = response.data as Outlet[];
        }
        return res;
    } catch (e) {
        console.log('errr', e);
        if (axios.isCancel(e))
            return [];

        throw e;
    }

}

export const saveChmsoftOutletTelegramChatId = (chmsoftOutletId: number, telegramChatId?: string | null) => {
    let url = '/outlets/telegram-chat?chmsoftOutletId=' + chmsoftOutletId;
    if (!!telegramChatId)
        url += '&telegramChatId=' + telegramChatId;

    return  axiosInstance.post(url);
}