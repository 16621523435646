import React, {ChangeEvent, memo, useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {Outlet} from "../../types/outlet";
import trim from "lodash/trim";

type OwnProps = {
    outlet: Outlet,
    onChange: (outletId: number, chatId?: string) => void
}

export const TelegramChatId = memo<OwnProps>(({outlet, onChange}) => {
    const [chatId, setChatId] = useState(outlet.telegramChatId || '');

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setChatId(trim(event.target.value))
    };

    const handleBlur = () => {
        onChange(outlet.id, chatId);
    }

    useEffect(() => {
        setChatId(outlet.telegramChatId || '');
    }, [outlet.telegramChatId]);

    return <TextField value={chatId} fullWidth
               onBlur={handleBlur}
               onChange={handleChange}/>
});