import {GoodsRestRestriction} from "../../../types/goodsrestrestriction/intex";
import React, {memo, useCallback, useState} from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {Outlet} from "../../../types/outlet";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {EditQty} from "./EditQty";
import Tooltip from '@mui/material/Tooltip';

type OwnProps = {
    restriction: GoodsRestRestriction,
    onClose: () => void,

}

const EditRestrictionQty = memo<OwnProps>(({restriction, onClose}) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = useCallback(() => {
        setOpen(false);
        onClose();
    }, [setOpen, onClose])

    const handleSaveComplete = useCallback(() => {
        onClose();
    }, [onClose]);


    return <>
        {open ? (<EditQty restriction={restriction} onSaveComplete={handleSaveComplete} onClose={handleClose}/>) : (
            <Stack spacing={1} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Box>{restriction.minQuantity}</Box>
                <Tooltip title="Изменить количество" arrow>
                    <IconButton size={"medium"} onClick={(_) => setOpen(true)}>
                        <EditIcon fontSize="inherit" color={"action"}/>
                    </IconButton>
                </Tooltip>
            </Stack>
        )}


    </>
})

export default EditRestrictionQty;