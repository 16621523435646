import React, {memo} from "react";
import {Summary} from "../../types/summary";
import {Box, Card, CardContent, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SendIcon from '@mui/icons-material/Send';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import MoneyIcon from '@mui/icons-material/Money';

type OwnPros = {
    summary: Summary
}

const SummaryCard = memo<OwnPros>(({summary}) => {
    return <Box sx={{width: '100%'}}>
        <Card variant="outlined">
            <CardContent>
                <Stack component={"div"} spacing={2} alignItems={"center"} justifyContent={"flex-start"}
                       direction={"row"} paddingBottom={1}>
                    <PersonIcon/>
                    <Typography variant="h6" component="div">
                        {summary.cashier}
                    </Typography>
                    {summary.excise && <Typography  color={"textSecondary"} component="div">
                        AКЦИЗ
                    </Typography>}
                </Stack>
                <Stack direction={"column"} spacing={2}>
                    {renderThreeColumns(<ReceiptIcon sx={{color: 'gray'}}/>, 'Всего чеков', summary.totalChecks)}
                    {renderThreeColumns(<SendIcon sx={{color: 'green'}}/>, 'Отправленных', summary.successChecks)}
                    {renderThreeColumns(<SmsFailedIcon sx={{color: 'red'}}/>, 'С ошибками', summary.errorChecks)}
                    {renderThreeColumns(<MoneyIcon sx={{color: '#BAB10A'}}/>, 'Сумма (грн)', formatNumber(summary.sum / 100, 2))}
                </Stack>
            </CardContent>
        </Card>
    </Box>
});

function formatNumber(src: number, decimals: number) {
    let number = 1;
    for (let i = 0; i <= decimals ; i++)
        number = number * 10;

    const rounded = Number(Number(src).toFixed(decimals));
    return ((Math.round(rounded * number) / number).toFixed(decimals))
}

function renderThreeColumns(icon: React.ReactNode, label: string, value: number | string) {
    return <Stack component={"div"} spacing={2} alignItems={"center"} justifyContent={"flex-start"} direction={"row"}>
        {icon}
        <Typography color={"textSecondary"} sx={{minWidth: '70%'}}>
            {label}
        </Typography>
        <Typography>{value}</Typography>
    </Stack>
}

export default SummaryCard;