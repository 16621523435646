import {ChangeEvent, useCallback, useEffect, useState} from "react";
import {Suggest} from "../../../types";
import {getGoodsSuggestions} from "../../../service/goods_rest_restriction";
import {getAxiosCancellationFunction} from "../../../utils/api";

export function useGoodsAutocomplete() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<readonly Suggest[]>([]);
    const [keyword, setKeyword] = useState('');

    const onKeywordChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setKeyword(event.target.value);
    }, [setKeyword])

    useEffect(() => {
        if (!open) {
            setKeyword('');
            setOptions([]);
        }

        let mounted = true;
        const abCtrl = new AbortController();
        setLoading(true);

        getGoodsSuggestions(abCtrl, keyword)
            .then((data) => {
                if (mounted) setOptions(data);
            }).catch(error => {
            console.error(error);
        }).finally(() => {
            if (mounted) setLoading(false)
        })

        return getAxiosCancellationFunction(mounted, abCtrl)
    }, [open, keyword]);

    return {
        open,
        options,
        loading,
        setOpen,
        onKeywordChange,
    }
}