import React, {useCallback, MouseEvent} from "react";
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme} from "@mui/material";
import {useLocation} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {Location} from "@remix-run/router/history";
import map from "lodash/map";
import mainMenuItems, {MainMenuItem} from "../../../constants/mainMenu";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function SideBar() {
    const theme = useTheme();
    let location: Location = useLocation();
    const navigate = useNavigate();
    // const matches = useMediaQuery('(min-width:600px)');
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const redirect = useCallback((to: string) => {
        return (event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLDivElement>) => {
            navigate(to)
        }
    }, [navigate]);

    return <Box sx={{
        width: matches ? 280 : 60,
        backgroundColor: 'rgb(244, 245, 250)',
        height: window.innerHeight,
    }}>
        <List>
            {map(mainMenuItems, (item: MainMenuItem) => {
                return <ListItem key={item.pathname} sx={{padding: 0}}
                                 selected={location.pathname === item.pathname}>
                    <ListItemButton onClick={redirect(item.pathname)}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        {matches && <ListItemText primary={item.title}/>}
                    </ListItemButton>
                </ListItem>
            })}
        </List>
    </Box>
};