import {axiosInstance} from "../utils/api";


export const signIn = async (email: string, password: string): Promise<string> => {
    const url = `/auth/sign-in?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;
    const response = await axiosInstance.get(url)

    if (response.status === 200) {
        return Promise.resolve(response.data);
    } else if (response.status === 204) {
        return Promise.reject('Пользователь не найден.');
    }

    return Promise.reject(`Во время авторизации произошла ошибка. ${response.statusText}`);
}