import React, {ReactNode} from "react";
import {Grid, Paper} from "@mui/material";
import map from "lodash/map";
import isArray from "lodash/isArray";

type OwnProps = {
    children?: ReactNode | ReactNode[]
};

const Filters = ({children}: OwnProps) => {
    const childs = isArray(children) ? children : [children];

    return <Paper sx={{padding: '0.5rem'}}>
        <Grid container justifyContent={"flex-start"} direction={"row"} spacing={1} alignItems={"center"}>
            {map(childs, (chld, idx) => <Grid item key={idx}>{chld}</Grid>)}
        </Grid>
    </Paper>
};

export default Filters;