import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import theme from "./theme";
import Preloader from "./components/Preloader";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import AuthorizationProvider from "./components/AuthorizationProvider";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthorizationProvider>
            <Preloader>
                <Router>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <ThemeProvider theme={theme}>
                            <App/>
                        </ThemeProvider>
                    </LocalizationProvider>
                </Router>
            </Preloader>
        </AuthorizationProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
