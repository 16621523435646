import React, {memo, useCallback, useState} from "react";
import {PostedCheck} from "../../types/postedcheck";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import {Button, Dialog, DialogActions, DialogContent, Stack} from "@mui/material";

type OwnProps = {
    check: PostedCheck
}

const CheckStatus = memo<OwnProps>(({check}) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen])

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen])

    if (check.status === 'SUCCESS') {
        return <Typography sx={{color: 'green'}}>{check.status}</Typography>
    }

    return <>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography sx={{color: 'red'}}>{check.status}</Typography>
            <IconButton color="primary"
                        onClick={handleOpen}
                        size={"small"}>
                <HelpIcon/>
            </IconButton>
        </Stack>
        <Dialog open={open}
                onClose={handleClose}>
            <DialogContent sx={{lineHeight: '140%'}}>
                {check.status === 'ERROR' ? check.details : "При отправке этого чека что-то пошло не так. Нет данных создался данный чек в Чекбокс или нет."}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    </>
});

export default CheckStatus;