import React from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import {Fab, Grid, Stack, Typography} from "@mui/material";
import Page from "../template/Page";
import {useDashboard} from "./useDashboard";
import isEmpty from "lodash/isEmpty";
import ErrorAlert from "../../components/ErrorAlert";
import {errorMessage} from "../../constants/text";
import usePreloader from "../../hooks/usePreloader";
import map from "lodash/map";
import SummaryCard from "./SummaryCard";
import CachedIcon from "@mui/icons-material/Cached";

export default function Dashboard(): JSX.Element {
    const {error, loading, clearError, summaries, reload} = useDashboard();
    usePreloader(loading);

    return <Page title={"Главная"} icon={<DashboardIcon/>}>
        <Stack direction="column" spacing={2}>
            {!isEmpty(error) && <ErrorAlert onClose={clearError}>
                {error?.message || errorMessage.defaultMessage}
            </ErrorAlert>}

            <Grid container sx={{padding: '1rem'}} spacing={3}>
                {map(summaries, (summary, idx) => <Grid item md={4} xs={12} key={idx}>
                    <SummaryCard key={idx} summary={summary}/>
                </Grid>)}
                {isEmpty(summaries) && <Grid item xs={12}>
                    <Typography variant={"h5"}>Нет информации для отображения</Typography>
                </Grid>}
                <Grid item xs={12} textAlign={"right"}>
                    <Fab aria-label="обновить"
                         onClick={reload}
                         title={"Обновить"}
                         size={"large"}
                         color={"info"}>
                        <CachedIcon />
                    </Fab>
                </Grid>
            </Grid>
        </Stack>
    </Page>
};