import {useCallback, useEffect, useState} from "react";
import {Summary} from "../../types/summary";
import {getAxiosCancellationFunction} from "../../utils/api";
import {searchSummary} from "../../service/summary";

export function useDashboard() {
    const [version, setVersion] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [summaries, setSummaries] = useState<Summary[] | undefined>([]);

    useEffect(() => {
        let mounted = true;
        const abCtrl = new AbortController();
        setLoading(true);

        searchSummary(abCtrl)
            .then(value => {
                if (mounted) setSummaries(value);
            }).catch(error => {
            if (mounted) setError(error);
        }).finally(() => {
            if (mounted) setLoading(false)
        })

        return getAxiosCancellationFunction(mounted, abCtrl)

    }, [version]);

    return {
        loading,
        error,
        clearError: useCallback(() => setError(undefined), [setError]),
        reload: useCallback(() => setVersion(version + 1), [version]),
        summaries
    }
}