import React, {memo, useCallback, useState} from "react";
import {PostedCheck} from "../../types/postedcheck";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import Typography from "@mui/material/Typography";

type OwnProps = {
    check: PostedCheck,
    onDelete: (check: PostedCheck) => void
}

const DeleteCheck = memo<OwnProps>(({check, onDelete}) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen])

    const handleDelete = useCallback(() => {
        handleClose();
        onDelete(check);
    }, [check, onDelete, handleClose]);

    return <>
        <IconButton size={"medium"}
                    onClick={useCallback(() => setOpen(true), [setOpen])}>
            <DeleteIcon fontSize="inherit" color={"error"}/>
        </IconButton>
        <Dialog open={open}
                onClose={handleClose}>
            <DialogContent sx={{lineHeight: '120%'}}>
                <Typography variant={"body1"}>Вы действительно хотете удалить чек?</Typography>
                <Typography sx={{pt: '0.5rem'}} variant={"body2"}>Удаленный чек будет повторно отправлен в чекбокс! Это может привести к дублированию чеков.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete}>Удалить</Button>
                <Button onClick={handleClose}>Отмена</Button>
            </DialogActions>
        </Dialog>
    </>
})

export default DeleteCheck;