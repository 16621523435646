import {CashiersSearchCriteria, searchCheckboxCashiers} from "../../service/cashiers";
import {useCallback, useEffect, useState} from "react";
import {CheckboxCashier} from "../../types/employee";
import {getAxiosCancellationFunction} from "../../utils/api";

export function useCashiers(filters: CashiersSearchCriteria) {
    const [version, setVersion] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [cashiers, setCashiers] = useState<CheckboxCashier[] | undefined>([]);

    useEffect(() => {
        let mounted = true;
        const abCtrl = new AbortController();
        setLoading(true);

        searchCheckboxCashiers(filters, abCtrl)
            .then(value => {
                if (mounted) setCashiers(value);
            }).catch(error => {
            if (mounted) setError(error);
        }).finally(() => {
            if (mounted) setLoading(false)
        })

        return getAxiosCancellationFunction(mounted, abCtrl)

    }, [filters, version]);


    return {
        loading,
        error,
        clearError: useCallback(() => setError(undefined), [setError]),
        reload: useCallback(() => setVersion(version + 1), [version]),
        cashiers
    }
}