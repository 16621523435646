import React, {ReactNode, useContext, memo, createContext, useMemo, useEffect} from "react";
import {useCookies} from 'react-cookie';
import {setAuthorization} from "../utils/api";
import {Cookie} from "../constants/cookies";

type OwnProps = {
    children: ReactNode
}

export interface IAuthorizationContext {
    token?: string,
    setToken: (token?: string) => void
}

export const AuthorizationContext = createContext<IAuthorizationContext | null>(null);

const AuthorizationProvider = memo(({children}: OwnProps) => {
    const [cookies, setCookies, removeCookie] = useCookies();

    const value: IAuthorizationContext = useMemo(() => ({
        token: cookies[Cookie.TOKEN],
        setToken: (token?: string) => {
            if (!!token) {
                setCookies(Cookie.TOKEN, token);
            } else {
                removeCookie(Cookie.TOKEN);
            }
        },
    }), [cookies, setCookies, removeCookie])

    useEffect(() => {
        if (!!cookies[Cookie.TOKEN])
            setAuthorization(cookies[Cookie.TOKEN])
    }, [cookies[Cookie.TOKEN]])

    return <AuthorizationContext.Provider value={value}>
        {children}
    </AuthorizationContext.Provider>
})

export const useAuthorization = () => {
    return useContext<IAuthorizationContext | null>(AuthorizationContext)
}

export default AuthorizationProvider;