import {CheckboxCashier} from "../../../types/employee";
import {memo, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import isEmpty from "lodash/isEmpty";
import toString from "lodash/toString";
import {getCashierValidationSchema} from "./validation";
import {useFormik} from "formik";
import {DialogContentText, MenuItem} from "@mui/material";
import usePreloader from "../../../hooks/usePreloader";
import ErrorAlert from "../../../components/ErrorAlert";
import {saveCheckboxCashier} from "../../../service/cashiers";

function getEmptyCheckboxCashier(): CheckboxCashier {
    return {id: 0, login: '', password: '', active: true, name: '', pin: '', licenseKey: '', terminal: ''};
}

type OwnProps = {
    open: boolean,
    cashier?: CheckboxCashier
    onClose: () => void
};

export const EditCashierDialog = memo<OwnProps>((props) => {
    const {open, cashier, onClose} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>()
    const initialValues = Object.assign<CheckboxCashier, CheckboxCashier | undefined>(getEmptyCheckboxCashier(), cashier);
    const validationSchema = getCashierValidationSchema();
    usePreloader(loading);

    const formik = useFormik<CheckboxCashier>({
        validateOnChange: false,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values => {
            setLoading(true);
            saveCheckboxCashier(values)
                .then(_ => onClose())
                .catch(error => setError(error))
                .finally(() => setLoading(false));
        })
    })

    const handleClose = () => {
        formik.setValues(initialValues, false);
        setError(undefined);
        onClose();
    }

    const edit = !isEmpty(cashier) && !!cashier?.id;
    return <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle>{edit ? 'Редактирование' : 'Создание'} Кассира</DialogTitle>
        {!!error && <DialogContentText>
            <ErrorAlert onClose={() => setError(undefined)}>
                {error.message}
            </ErrorAlert>
        </DialogContentText>}
        <DialogContent>
            <TextField fullWidth autoFocus
                       label="Имя"
                       name="name"
                       margin="dense"
                       variant="standard"
                       error={formik.touched.name && Boolean(formik.errors.name)}
                       helperText={formik.touched.name && formik.errors.name}
                       onChange={formik.handleChange}
                       value={formik.values.name}/>

            <TextField fullWidth
                       label="Логин"
                       name="login"
                       margin="dense"
                       variant="standard"
                       error={formik.touched.login && Boolean(formik.errors.login)}
                       helperText={formik.touched.login && formik.errors.login}
                       onChange={formik.handleChange}
                       value={formik.values.login}/>

            <TextField fullWidth
                       label="Пин"
                       name="pin"
                       margin="dense"
                       variant="standard"
                       error={formik.touched.pin && Boolean(formik.errors.pin)}
                       helperText={formik.touched.pin && formik.errors.pin}
                       onChange={formik.handleChange}
                       value={formik.values.pin}/>

            <TextField fullWidth select
                       label="Статус"
                       name="active"
                       margin="dense"
                       variant="standard"
                       error={formik.touched.active && Boolean(formik.errors.active)}
                       helperText={formik.touched.active && formik.errors.active}
                       onChange={formik.handleChange}
                       value={toString(formik.values.active)}>
                <MenuItem value={'true'}>Активный</MenuItem>
                <MenuItem value={'false'}>Не Активный</MenuItem>
            </TextField>

            <TextField fullWidth
                       label="Пароль"
                       name="password"
                       margin="dense"
                       variant="standard"
                       error={formik.touched.password && Boolean(formik.errors.password)}
                       helperText={formik.touched.password && formik.errors.password}
                       onChange={formik.handleChange}
                       value={formik.values.password}/>

            <TextField fullWidth
                       label="Ключ Лицензии Кассы"
                       name="licenseKey"
                       margin="dense"
                       variant="standard"
                       error={formik.touched.licenseKey && Boolean(formik.errors.licenseKey)}
                       helperText={formik.touched.licenseKey && formik.errors.licenseKey}
                       onChange={formik.handleChange}
                       value={formik.values.licenseKey}/>

            <TextField fullWidth
                       label="Терминал"
                       name="terminal"
                       margin="dense"
                       variant="standard"
                       error={formik.touched.terminal && Boolean(formik.errors.terminal)}
                       helperText={formik.touched.terminal && formik.errors.terminal}
                       onChange={formik.handleChange}
                       value={formik.values.terminal}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={formik.submitForm}>Сохранить</Button>
            <Button onClick={handleClose}>Отмена</Button>
        </DialogActions>
    </Dialog>
})