import {memo, useCallback, useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import {EditCashierDialog} from "./EditCashierDialog";
import {CheckboxExciseCashier} from "../../../types/employee";
import {Button} from "@mui/material";

type OwnProps = {
    cashier: CheckboxExciseCashier,
    onClose: () => void
};

export const EditCashier = memo<OwnProps>(({cashier, onClose}) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    const handleClose = useCallback(() => {
        setOpen(false);

        onClose();
    }, [setOpen, onClose]);

    return <>
        <Button onClick={handleOpen} size={"medium"} startIcon={<EditIcon fontSize="inherit" color={"secondary"}/>}>
            Изменить
        </Button>
        <EditCashierDialog open={open} cashier={cashier} onClose={handleClose}/>
    </>
})