import React, {memo, useCallback, useState} from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import {GoodsRestRestriction} from "../../types/goodsrestrestriction/intex";
import Tooltip from '@mui/material/Tooltip';

type OwnProps = {
    restriction: GoodsRestRestriction,
    onDelete: (restriction: GoodsRestRestriction) => void
}

const DeleteRestriction = memo<OwnProps>(({restriction, onDelete}) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen])

    const handleDelete = useCallback(() => {
        handleClose();
        onDelete(restriction);
    }, [restriction, onDelete, handleClose]);

    return <>
        <Tooltip title="Удалить" arrow>
            <IconButton size={"medium"}
                        onClick={useCallback(() => setOpen(true), [setOpen])}>
                <DeleteIcon fontSize="inherit" color={"error"}/>
            </IconButton>
        </Tooltip>
        <Dialog open={open}
                onClose={handleClose}>
            <DialogContent sx={{lineHeight: '120%'}}>
                <Typography variant={"body1"}>Удалить проверку наличия товара на остатках?</Typography>
                <Typography variant={"body1"} color={"textSecondary"}>{restriction.chmsoftGoodsName}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete}>Удалить</Button>
                <Button onClick={handleClose}>Отмена</Button>
            </DialogActions>
        </Dialog>
    </>
})

export default DeleteRestriction;