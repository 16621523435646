import React, {ReactNode} from "react";
import {Path} from "./path";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import GroupsIcon from '@mui/icons-material/Groups';
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

export interface MainMenuItem {
    title: string,
    pathname: string,
    icon: ReactNode
}

const mainMenuItems: MainMenuItem[] = [
    {title: 'Главная', pathname: Path.DASHBOARD, icon: <DashboardIcon/>},
    {title: 'Чеки', pathname: Path.POSTED_CHECKS, icon: <ReceiptIcon/>},
    {title: 'Кассиры', pathname: Path.CASHIERS, icon: <PeopleIcon/>},
    {title: 'Кассиры Акциз', pathname: Path.EXCISE_CASHIERS, icon: <GroupsIcon/>},
    {title: 'Магазины', pathname: Path.OUTLETS, icon: <StoreIcon/>},
    {title: 'Критические Остатки', pathname: Path.GOODS_REST, icon: <NotificationImportantIcon/>},
    {title: 'Настройки', pathname: Path.SETTINGS, icon: <SettingsIcon/>},
]

export default mainMenuItems;