import React, {ReactNode} from "react";
import Box from "@mui/material/Box";

type OwnProps = {
    children?: ReactNode | ReactNode[]
};

const Content = ({children}: OwnProps) => (
    <Box sx={{margin: 1}}>
        {children}
    </Box>
);

export default Content;