import {useCallback, useEffect, useState} from "react";
import {Dictionary} from "../../types";
import {getProperties,  saveProperty} from "../../service/settings";

export function useProperties() {
    const [version, setVersion] = useState<number>(0);
    const [properties, setProperties] = useState<Dictionary<string>>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);

    useEffect(() => {
        let mounted = true;
        const abCtrl = new AbortController();
        setLoading(true);

        getProperties(abCtrl)
            .then(properties => {
                if (mounted) setProperties(properties);
            }).catch(error => {
            if (mounted) setError(error);
        }).finally(() => {
            if (mounted) setLoading(false);
        })
    }, [setProperties, setLoading, setError, version]);

    const saveSingle = useCallback((key: string, value?: string) => {
        setLoading(true);

        saveProperty(key, value)
            .then(() => setVersion(version + 1))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }, [version, setVersion, setLoading, setError]);


    return {
        loading,
        error,
        properties,
        clearError: useCallback(() => setError(undefined), [setError]),
        saveProperty: saveSingle,
    }
}