import {useContext, useEffect} from "react";
import {PreloaderContext} from "../components/Preloader";

export default function usePreloader(loading: boolean) {
    const ctx = useContext(PreloaderContext);

    useEffect(() => {
        if (ctx !== null)
            ctx.setLoading(loading);
    }, [loading])
};