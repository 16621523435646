import {CheckboxCashier} from "../../types/employee";
import {memo} from "react";
import {FormControl, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import map from "lodash/map";
import {Outlet} from "../../types/outlet";

type OwnProps = {
    outlet: { id: number },
    cashier?: { id: number },
    options?: CheckboxCashier[],
    onSelect: (chmsoftId: number, checkboxId?: string | number | null) => void
}

export const CashierSelect = memo<OwnProps>(({outlet, cashier, options = [], onSelect}) => {
    const value = cashier?.id.toString() || '';

    const handleChange = (event: SelectChangeEvent) => {
        onSelect(outlet.id, event.target.value)
    };

    return <FormControl fullWidth>
        <Select value={value}
                onChange={handleChange}>
            <MenuItem value=""><em>Не задано</em></MenuItem>
            {map(options, (option: CheckboxCashier) => (
                <MenuItem disabled={!option.active} key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
        </Select>
    </FormControl>
});