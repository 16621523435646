export const Path = {
    INDEX: '',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    POSTED_CHECKS: '/checks',
    CASHIERS: '/cashiers',
    EXCISE_CASHIERS: '/excise-cashiers',
    SETTINGS: '/settings',
    OUTLETS: '/outlets',
    GOODS_REST: '/goods-rest',
}