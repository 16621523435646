import {CheckboxCashier, CheckboxExciseCashier} from "../types/employee";
import {axiosInstance} from "../utils/api";
import axios from 'axios';
import {useExciseCashiers} from "../page/ExciseCashiers/useExciseCashiers";

export interface CashiersSearchCriteria {
    onlyActive?: boolean;
}

export const searchCheckboxCashiers = async (filters: CashiersSearchCriteria, controller: AbortController): Promise<CheckboxCashier[]> => {
    let url = '/checkbox/cashiers/search';
    if (filters.onlyActive !== undefined) {
        url += '?onlyActive=' + filters.onlyActive;
    }

    try {
        const response = await axiosInstance.get(url, {
            signal: controller.signal
        });

        let res: CheckboxCashier[] = [];
        if (!!response && response.data) {
            res = response.data as CheckboxCashier[];
        }

        return res;
    } catch (e) {
        if (axios.isCancel(e))
            return [];

        throw e;
    }
}

export const searchCheckboxExciseCashiers = async (filters: CashiersSearchCriteria, controller: AbortController): Promise<CheckboxExciseCashier[]> => {
    let url = '/checkbox/excise-cashiers/search';
    if (filters.onlyActive !== undefined) {
        url += '?onlyActive=' + filters.onlyActive;
    }

    try {
        const response = await axiosInstance.get(url, {
            signal: controller.signal
        });

        let res: CheckboxExciseCashier[] = [];
        if (!!response && response.data) {
            res = response.data as CheckboxExciseCashier[];
        }

        return res;
    } catch (e) {
        if (axios.isCancel(e))
            return [];

        throw e;
    }
}

export const linkChmsoftOutletToCheckboxCashier = (chmsoftOutletId: number, checkboxCashierId?: number | string | null) =>{
    let url = '/checkbox/cashiers/assign-outlet?chmsoftOutletId=' + chmsoftOutletId;
    if (!!checkboxCashierId)
        url += '&checkboxCashierId=' + checkboxCashierId;

    return  axiosInstance.post(url);
}

export const saveCheckboxCashier = async (cashier: CheckboxCashier) => {
    return await axiosInstance.post('/checkbox/cashiers', cashier);
}

export const deleteCheckboxCashier = async (cashierId: number) => {
    return await axiosInstance.delete('/checkbox/cashiers?id=' + cashierId,);
}

export const deleteCheckboxExciseCashier = async (cashierId: number) => {
    return await axiosInstance.delete('/checkbox/excise-cashiers?id=' + cashierId,);
}

export const saveCheckboxExciseCashier = async (cashier: CheckboxExciseCashier) => {
    return await axiosInstance.post('/checkbox/excise-cashiers', cashier);
}

export const linkChmsoftOutletToCheckboxExciseCashier = (chmsoftOutletId: number, checkboxCashierId?: number | string | null) =>{
    let url = '/checkbox/excise-cashiers/assign-outlet?chmsoftOutletId=' + chmsoftOutletId;
    if (!!checkboxCashierId)
        url += '&checkboxCashierId=' + checkboxCashierId;

    return  axiosInstance.post(url);
}
