import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import React, {useCallback, useMemo, useState} from "react";
import Page from "../template/Page";
import {Grid, MenuItem, Paper, Stack, TablePagination, TextField} from "@mui/material";
import Content from "../template/content/Content";
import {GoodsRestRestrictionSearchCriteria} from "../../service/goods_rest_restriction";
import {Dictionary, OrderBy} from "../../types";
import {useGoodsRestRestrictions} from "./useGoodsRestRestrictions";
import map from "lodash/map";
import filter from "lodash/filter";
import {Outlet} from "../../types/outlet";
import isEmpty from "lodash/isEmpty";
import ErrorAlert from "../../components/ErrorAlert";
import {errorMessage} from "../../constants/text";
import {GoodsRestRestriction} from "../../types/goodsrestrestriction/intex";
import {AddRestriction} from "./add/AddRestriction";
import {sxPageTable} from "../../constants/style";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import {NoDataFoundTableRow} from "../../components/NoDataFoundTableRow";
import TableBody from "@mui/material/TableBody";
import reduce from "lodash/reduce";
import DeleteRestriction from "./DeleteRestriction";
import usePreloader from "../../hooks/usePreloader";
import EditRestrictionQty from "./edit/EditRestrictionQty";
import {KeywordFilter} from "./KeywordFilter";


const GoodsRestRestrictions = () => {
    const defaultFilters: GoodsRestRestrictionSearchCriteria = {
        pageNumber: 1,
        rowsPerPage: 25,
    }

    const [filters, setFilters] = useState<GoodsRestRestrictionSearchCriteria>(defaultFilters);
    const [orderBy, setOrderBy] = useState<OrderBy>({
        field: 'createDate', direction: 'DESC'
    });

    const handleChangePage = useCallback((event: unknown, newPage: number) => {
        setFilters({...filters, pageNumber: newPage + 1})
    }, [filters]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({...filters, pageNumber: 1, rowsPerPage: parseInt(event.target.value, 10)})
    };

    const {loading, clearError, reload, error, page, deleteRestriction, outlets
    } = useGoodsRestRestrictions(filters, orderBy);

    const handleSelectFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({...filters, [event.target.name]: event.target.value as any, pageNumber: 1})
    }, [filters, setFilters])


    const handleKeywordFilterChanged = useCallback((value?: string) => {
        setFilters({...filters, keyword: value, pageNumber: 1})
    }, [filters, setFilters])

    let outletNamePerId = useMemo(() => {
        let res: Dictionary<string> = {};
        if (isEmpty(outlets))
            return res;

        return reduce(outlets, (acc: Dictionary<string>, outlet: Outlet) => {
            acc[outlet.id] = outlet.outletName;
            return acc;
        }, {} as Dictionary<string>)

    }, [outlets]);

    usePreloader(loading);

    return <Page title="Критические Остатки" icon={<NotificationImportantIcon/>}>
        <Stack direction="column" spacing={2}>
            <Paper sx={{padding: '0.5rem'}}>
                <Grid container justifyContent={"space-between"} direction={"row"} spacing={1} alignItems={"center"}>
                    <Grid item md={10} sm={10}>
                        <Stack spacing={1} direction={"row"}>
                            <TextField select value={filters.outletId || ''}
                                       name={"outletId"}
                                       label={"Магазин"}
                                       onChange={handleSelectFilterChange}
                                       fullWidth sx={{minWidth: '17rem', maxWidth: '18rem'}}>
                                <MenuItem value={''}>&nbsp;</MenuItem>
                                {map(filter(outlets, (outlet: Outlet) => outlet.active), (outlet) => (<MenuItem key={outlet.id} value={outlet.id}>{outlet.outletName}</MenuItem>))}
                            </TextField>

                            <KeywordFilter keyword={filters.keyword || ''} onChange={handleKeywordFilterChanged}/>
                        </Stack>
                    </Grid>

                    <Grid item md={2} sm={2} sx={{textAlign: 'right'}}>
                        <AddRestriction onClose={reload} outlets={outlets}/>
                    </Grid>
                </Grid>
            </Paper>

            {!!error && <ErrorAlert onClose={clearError}>
                {error?.message || errorMessage.defaultMessage}
            </ErrorAlert>}

            <Content>
                <TableContainer component={Paper}>
                    <Table sx={sxPageTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Магазин</TableCell>
                                <TableCell>ID Товара</TableCell>
                                <TableCell>Название Товара</TableCell>
                                <TableCell sx={{width: '14rem'}}>Мин. Кол-во</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(isEmpty(page) || isEmpty(page.results)) && <NoDataFoundTableRow colSpan={5}/>}
                            {!isEmpty(page) && map(page.results, (item: GoodsRestRestriction) => {
                                return <TableRow key={item.id}>
                                    <TableCell>{outletNamePerId[item.chmsoftOutletId] || '...'}</TableCell>
                                    <TableCell>{item.chmsoftGoodsId}</TableCell>
                                    <TableCell>{item.chmsoftGoodsName}</TableCell>
                                    <TableCell sx={{p: 0.5}}>
                                        <EditRestrictionQty restriction={item} onClose={reload}/>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                            <DeleteRestriction restriction={item} onDelete={deleteRestriction}/>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Content>
            <Paper>
                <TablePagination
                    labelRowsPerPage={"Товаров на странице"}
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500, 1000]}
                    component="div"
                    count={page.totalResults}
                    rowsPerPage={filters.rowsPerPage}
                    page={filters.pageNumber - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
            </Paper>
        </Stack>
    </Page>
}

export default GoodsRestRestrictions;