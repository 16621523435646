const reg = new RegExp("(http[s]?)://([^/:]+)(:[\\d]+)?/","igm");

const HTTP_PORT = '80';
// @ts-ignore
const HTTPS_PORT = '8443';

interface IUrlInfo {
    getUrl(): string;
    getDomine(): string;
    getScheme(): string;
    getPort(): string;
}

export class UrlInfo implements IUrlInfo{
    private readonly domine: string;
    private readonly port: string;
    private readonly scheme: string;
    private readonly source: string;
    private readonly url: string;
    private readonly match: RegExpExecArray | null;

    constructor(url: string) {
        this.source = url;
        this.match = reg.exec(this.source);
        this.url = this.match && this.match[0] ? this.match[0] : '';
        this.scheme = this.match && this.match[1] ? this.match[1] : '';
        this.domine = this.match && this.match[2] ? this.match[2] : '';
        this.port = this.match && this.match[3] ? this.match[3].substr(1) : HTTP_PORT;
    }

    getUrl(){
        return this.url
    }

    getDomine(){
        return  this.domine
    }

    getScheme(){
        return this.scheme;
    }

    getPort() {
        if (isSecuredScheme(this.scheme)) {
            return HTTPS_PORT;
        }
        return this.port;
    }

    isSecured() {
        return isSecuredScheme(this.scheme);
    }
}

const isSecuredScheme = (scheme: string) => scheme.indexOf('s') > 0;