import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

type OwnProps = {
    colSpan?: number,
    message?: string
}

export const NoDataFoundTableRow = React.memo((props: OwnProps) => {
    const {colSpan = 1, message = 'Ничего не найдено'} = props;
 return <TableRow>
     <TableCell colSpan={colSpan}>{message}</TableCell>
 </TableRow>
})