import React, {FormEvent, useCallback, useState} from "react";
import GroupsIcon from '@mui/icons-material/Groups';
import Page from "../template/Page";
import {
    Avatar,
    Card, CardActions,
    CardContent,
    CardHeader,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Stack
} from "@mui/material";
import {OutletsSearchCriteria} from "../../service/outlets";
import {FilterActive} from "../filters/FilterActive";
import ErrorAlert from "../../components/ErrorAlert";
import {errorMessage} from "../../constants/text";
import {useExciseCashiers} from "./useExciseCashiers";
import {CashiersSearchCriteria} from "../../service/cashiers";
import Content from "../template/content/Content";
import isEmpty from "lodash/isEmpty";
import NothingFound from "../../components/NothingFound";
import map from "lodash/map";
import {AddCashier} from "./edit/AddCashier";
import {CheckboxCashier, CheckboxExciseCashier} from "../../types/employee";
import FaceIcon from "@mui/icons-material/Face";
import PasswordIcon from "@mui/icons-material/Password";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Typography from "@mui/material/Typography";
import PaymentIcon from "@mui/icons-material/Payment";
import EuroIcon from '@mui/icons-material/Euro';
import {EditCashier} from "./edit/EditCashier";
import {DeleteCashier} from "./edit/DeleteCashier";
import usePreloader from "../../hooks/usePreloader";

export default function ExciseCashiers() {
    const [filters, setFilters] = useState<CashiersSearchCriteria>({onlyActive: true})
    const handleActiveFilterChange = useCallback((_: FormEvent<HTMLInputElement>) => {
        setFilters({onlyActive: !filters.onlyActive});
    }, [filters, setFilters]);

    const {loading, error, reload, clearError, cashiers} = useExciseCashiers(filters);
    usePreloader(loading);

    return <Page title="Кассиры акциз" icon={<GroupsIcon/>}>
        <Stack direction="column" spacing={2}>
            <Paper sx={{padding: '0.5rem'}}>
                <Grid container justifyContent={"space-between"} direction={"row"} spacing={1} alignItems={"center"}>
                    <Grid item md={10} sm={10}>
                        <FilterActive value={filters.onlyActive} onChange={handleActiveFilterChange}/>
                    </Grid>
                    <Grid item md={2} sm={2} sx={{textAlign: 'right'}}>
                        <AddCashier onClose={reload}/>
                    </Grid>
                </Grid>
            </Paper>

            {!!error && <ErrorAlert onClose={clearError}>
                {error?.message || errorMessage.defaultMessage}
            </ErrorAlert>}

            <Content>
                {isEmpty(cashiers) && <NothingFound/>}

                <Grid container justifyContent={"flex-start"} spacing={2}>
                    {map(cashiers, (cashier: CheckboxExciseCashier) => {
                        return <Grid key={cashier.id} item md={4} sm={12} sx={{width: '100%'}}>
                            <Card sx={{width: '100%'}} className={"disable-text-selection"}>
                                <CardHeader title={cashier.name} sx={{pb: 0}}
                                            subheader={<Subheader cashier={cashier}/>}/>
                                <CardContent>
                                    <List dense sx={{width: '100%'}}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <FaceIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Логин" secondary={cashier.login} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PasswordIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Пароль" secondary={cashier.password} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <VpnKeyIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Ключ кассы" secondary={
                                                <Typography variant={"body2"} color={"textSecondary"}
                                                            noWrap>{cashier.licenseKey}</Typography>
                                            } />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PaymentIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Терминал" secondary={
                                                <Typography variant={"body2"} color={"textSecondary"}
                                                            noWrap>{cashier.terminal || 'Не задано'}</Typography>
                                            } />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <EuroIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Дневной Лимит (грн)" secondary={
                                                <Typography variant={"body2"} color={"textSecondary"}
                                                            noWrap>{cashier.dailyLimit / 100}</Typography>
                                            } />
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <CardActions>
                                    <EditCashier cashier={cashier} onClose={reload}/>
                                    <DeleteCashier cashier={cashier} onClose={reload}/>
                                </CardActions>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </Content>
        </Stack>
    </Page>

}

const Subheader: React.FC<{ cashier: CheckboxCashier }> = ({cashier}) => (
    <Typography color={cashier.active ? "inherit" : "error"}>
        {cashier.active ? "Активный" : "Не активный"}
    </Typography>)