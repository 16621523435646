import {useCallback, useEffect, useState} from "react";
import {OutletsSearchCriteria, saveChmsoftOutletTelegramChatId, searchOutlets} from "../../service/outlets";
import {Outlet} from "../../types/outlet";
import {getAxiosCancellationFunction} from "../../utils/api";
import usePreloader from "../../hooks/usePreloader";
import {linkChmsoftOutletToCheckboxCashier, linkChmsoftOutletToCheckboxExciseCashier} from "../../service/cashiers";

export function useOutlets(filters: OutletsSearchCriteria) {
    const [version, setVersion] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [outlets, setOutlets] = useState<Outlet[] | undefined>([]);

    usePreloader(loading);

    useEffect(() => {
        let mounted = true;
        const abCtrl = new AbortController();
        setLoading(true);

        searchOutlets(filters, abCtrl)
            .then(value => {
                if (mounted) setOutlets(value);
            }).catch(error => {
            if (mounted) setError(error);
        }).finally(() => {
            if (mounted) setLoading(false)
        })

        return getAxiosCancellationFunction(mounted, abCtrl)

    }, [filters, version]);

    const linkToCheckboxCashier = useCallback(async (chmsoftOutletId: number, checkboxCashierId?: string | number | null) => {
        try {
            setLoading(true);
            const resp = await linkChmsoftOutletToCheckboxCashier(chmsoftOutletId, checkboxCashierId)
            setLoading(false);
            if (resp.status === 200) {
                setVersion(version + 1);
            } else {
                setError(new Error(resp.statusText))
            }
        } catch (e: any) {
            console.error("Ошибка при сохранании кассира", e);
            setError(new Error("Ошибка при сохранании кассира. " + e.message || ""));
            setLoading(false);
        }
    }, [setError, version])

    const linkToCheckboxExciseCashier = useCallback(async (chmsoftOutletId: number, checkboxCashierId?: string | number | null) => {
        try {
            setLoading(true);
            const resp = await linkChmsoftOutletToCheckboxExciseCashier(chmsoftOutletId, checkboxCashierId)
            setLoading(false);
            if (resp.status === 200) {
                setVersion(version + 1);
            } else {
                setError(new Error(resp.statusText))
            }
        } catch (e: any) {
            console.error("Ошибка при сохранании кассира", e);
            setError(new Error("Ошибка при сохранании кассира. " + e.message || ""));
            setLoading(false);
        }
    }, [setError, version])

    const saveTelegramChatId = useCallback(async (chmsoftOutletId: number, telegramChatId?: string | null) => {
        try {
            setLoading(true);
            const resp = await saveChmsoftOutletTelegramChatId(chmsoftOutletId, telegramChatId);
            setLoading(false);
            if (resp.status === 200) {
                setVersion(version + 1);
            } else {
                setError(new Error(resp.statusText))
            }
        } catch (err: any) {
            console.error("Ошибка при сохранании Telegram Chat ID", err);
            setError(new Error("Ошибка при сохранании Telegram Chat ID. " + err.message || ""));
            setLoading(false);
        }


    }, [setError, version])

    const clearError = useCallback(() => setError(undefined), [setError]);

    return {
        loading,
        error,
        clearError,
        outlets,
        linkToCheckboxCashier,
        linkToCheckboxExciseCashier,
        saveTelegramChatId
    }
}