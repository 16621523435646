import {PagedSearch, PagedSearchResult} from "../types/pagination";
import {OrderBy, Suggest} from "../types";
import {axiosInstance} from "../utils/api";
import axios from "axios";
import map from "lodash/map";
import {GoodsRestRestriction} from "../types/goodsrestrestriction/intex";

export interface GoodsRestRestrictionSearchCriteria extends PagedSearch {
    keyword?: string,
    outletId?: number
}

export const getGoodsSuggestions = async (controller: AbortController, name?: string) : Promise<Suggest[]> => {
    let url = '/checkbox/goods/suggestions?active=true&limit=20';
    if (!!name)
        url += '&keyword=' + encodeURIComponent(name);

    try {
        const response = await axiosInstance.get(url, {
            signal: controller.signal
        });

        if (response !== undefined) {
            return map(response.data, (goods: any) => ({label: goods.name, value: goods.id} as Suggest)) ;
        }

        return Promise.resolve([]);
    } catch (e) {
        if (axios.isCancel(e)) {
            return Promise.resolve([])
        }
        console.error(e);
        throw e;
    }
}

export const searchGoodsRestRestrictions = async (filters: GoodsRestRestrictionSearchCriteria, orderBy: OrderBy, controller: AbortController ): Promise<PagedSearchResult<GoodsRestRestriction>> => {
    const {pageNumber, rowsPerPage} = filters;
    let url = `/checkbox/goods-rest-restrictions/page?pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
    if (!!filters.outletId) {
        url += '&outletId=' + filters.outletId;
    }
    if (!!filters.keyword) {
        url += '&keyword=' + encodeURIComponent(filters.keyword);
    }
    if (!!orderBy) {
        url += '&orderBy=' + encodeURIComponent(orderBy.field);
        let direction = 'ASC';
        if (!!orderBy.direction) {
            direction = orderBy.direction;
        }
        url += '&sortDirection=' + direction;
    }

    try {
        const response = await axiosInstance.get(url, {
            signal: controller.signal
        });

        if (response !== undefined) {
            return response.data as PagedSearchResult<GoodsRestRestriction>;
        }

        return Promise.resolve({} as PagedSearchResult<GoodsRestRestriction>);
    } catch (e) {
        if (axios.isCancel(e)) {
            return Promise.resolve({} as PagedSearchResult<GoodsRestRestriction>)
        }
        console.error(e);
        throw e;
    }
}

export const saveGoodsRestRestriction = async (restRestriction: GoodsRestRestriction) => {
    return await axiosInstance.post('/checkbox/goods-rest-restrictions', restRestriction);
}

export const saveGoodsRestRestrictionMinQuantity = async(id: number, qty: number) => {
    return await axiosInstance.post('/checkbox/goods-rest-restrictions/min-quantity?id=' + id + '&qty=' + qty);
}


export const deleteGoodsRestRestriction = async (restRestriction: GoodsRestRestriction) => {
    let response = await axiosInstance.delete('/checkbox/goods-rest-restrictions?id=' + restRestriction.id);

    return response.data;
}