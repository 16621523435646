import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {EditCashierDialog} from "./EditCashierDialog";
import {useCallback, useState} from "react";

type OwnProps = {
    onClose: () => void
}

export const AddCashier = ({onClose}: OwnProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    const handleClose = useCallback(() => {
        setOpen(false);
        onClose();
    }, [setOpen, onClose]);

    return <>
        <IconButton onClick={handleOpen} size={"medium"}>
            <AddIcon fontSize="inherit" color={"secondary"}/>
        </IconButton>
        <EditCashierDialog open={open} onClose={handleClose}/>
    </>
}