import React, {ReactNode} from "react";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AppBar from "@mui/material/AppBar";

type OwnProps = {
    title: string,
    icon: ReactNode
}

export default function Header({title, icon}: OwnProps):JSX.Element {
    return <AppBar position="static" sx={{pt: 1, pb: 1}}>
        <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" sx={{ mr: 1 }}>
                {icon}
            </IconButton>
            <Typography variant="h6" color="inherit" component="div" fontFamily={"Eurostile"} sx={{mt: 1}}>
                {title}
            </Typography>
        </Toolbar>
    </AppBar>
};