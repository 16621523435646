import React, {useState} from "react";
import {Box, Button, Container, Stack, TextField, Typography} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import {getLoginValidationSchema} from "./validation";
import {useFormik} from "formik";
import {signIn} from "../../service/login";
import {useAuthorization} from "../../components/AuthorizationProvider";
import {useNavigate} from "react-router-dom";
import {Path} from "../../constants/path";
import ErrorAlert from "../../components/ErrorAlert";
import {errorMessage} from "../../constants/text";


const Login = () => {
    const [errMsg, setErrMsg] = useState<string | undefined>();
    const validationSchema = getLoginValidationSchema();
    const navigate = useNavigate();
    const authCtx = useAuthorization();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            signIn(values.email, values.password)
                .then((token) => {
                    if (!!authCtx) {
                        authCtx.setToken(token);
                        navigate(Path.DASHBOARD);
                    }
                }).catch((error) => {
                if (typeof error === "string") {
                    setErrMsg(error);
                } else {
                    setErrMsg(error.message);
                }
                console.error(error);
            })
        }
    });


    return <Container maxWidth={"xs"}>
        <Box mt={16} ml={2} mr={2} component="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box m={2} sx={{textAlign: "center"}}>
                <Stack alignItems="center" direction="column" justifyContent="center" spacing={2}>
                    <LockIcon sx={{fontSize: 48, color: '#adadad'}}/>
                    <Typography color="textSecondary" variant="h4">Вход</Typography>
                </Stack>
            </Box>
            {!!errMsg && <Box m={2}>
                <ErrorAlert onClose={() => setErrMsg(undefined)}>
                    {errMsg || errorMessage.defaultMessage}
                </ErrorAlert>
            </Box>}
            <Box m={2}>
                <TextField fullWidth
                           name="email"
                           label="Почта"
                           size="small"
                           variant="outlined"
                           error={formik.touched.email && Boolean(formik.errors.email)}
                           helperText={formik.touched.email && formik.errors.email}
                           onChange={formik.handleChange}
                           value={formik.values.email}/>
            </Box>
            <Box m={2}>
                <TextField fullWidth
                           name="password"
                           label="Пароль"
                           size="small"
                           variant="outlined"
                           type="password"
                           error={formik.touched.password && Boolean(formik.errors.password)}
                           helperText={formik.touched.password && formik.errors.password}
                           autoComplete="current-password"
                           onChange={formik.handleChange}
                           value={formik.values.password}
                />
            </Box>
            <Box m={2}>
                <Button fullWidth type={"submit"} variant={"contained"}>Войти</Button>
            </Box>
        </Box>
    </Container>
};

export default Login;