import React, {Dispatch, SetStateAction, ReactNode, useMemo, memo, useState} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';

const sxBackdrop = {
    zIndex: '9999',
    color: '#fff',
}

const sxIcon = {
    position: 'relative', right: '2.3rem'
}

type OwnProps = {
    children: ReactNode
}

export interface IPreloaderContext {
    setLoading: Dispatch<SetStateAction<boolean>>
}

export const PreloaderContext = React.createContext<IPreloaderContext | null>(null);

const Preloader = memo(({children}: OwnProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    const contextValue: IPreloaderContext = useMemo(() => ({
        setLoading: setLoading
    }), [setLoading]);

    return <PreloaderContext.Provider value={contextValue}>
        {children}
        <Backdrop sx={sxBackdrop} open={loading}>
            <CircularProgress color="inherit" size={'3rem'}/>
            <SyncIcon sx={sxIcon}/>
        </Backdrop>
    </PreloaderContext.Provider>
})

export default Preloader;