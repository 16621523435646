import React, {ReactNode} from "react";
import Header from "./Header/Header";
import {Box, Stack} from "@mui/material";
import SideBar from "./SideBar/SideBar";

type OwnProps = {
    title: string,
    icon: ReactNode,
    children: ReactNode
}

const Page = (props: OwnProps): JSX.Element => {
    const {title, icon, children} = props;
    return <>
        <Header title={title} icon={icon}/>
        <Stack direction={"row"}>
            <div>
                <SideBar/>
            </div>
            <Box p={1} sx={{width: '100%'}}>
                {children}
            </Box>
        </Stack>
    </>
};

export default Page;