import React from "react";
import {Container, List, ListItem, ListItemText, ListSubheader, Paper, Typography} from "@mui/material";
import values from "lodash/values";
import {Path} from "../constants/path"
import {useNavigate} from "react-router-dom";

const styles = {
    paper: {padding: '0.5rem', marginTop: '4rem'},
    item: {cursor: 'pointer', textDecoration: 'underline', color: '#0000EE'}
}

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return <Container maxWidth={"sm"}>
        <Paper sx={styles.paper}>
            <Typography color={"textSecondary"}>Запрашиваемая вами страница не существует!</Typography>
            <List subheader={
                <ListSubheader component="div">
                    Список доступных url
                </ListSubheader>}>
                {values(Path).map(path => (<ListItem sx={styles.item} onClick={() => navigate(path)}>
                    <ListItemText primary={path}/>
                </ListItem>))}
            </List>
        </Paper>
    </Container>
}

export default NotFound;