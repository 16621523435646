import React from "react";
import {Outlet} from "../../../types/outlet";
import {memo, useState} from "react";
import usePreloader from "../../../hooks/usePreloader";
import {useFormik} from "formik";
import isEmpty from "lodash/isEmpty";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from '@mui/material/Autocomplete';
import {CircularProgress, DialogContentText, MenuItem} from "@mui/material";
import ErrorAlert from "../../../components/ErrorAlert";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import toString from "lodash/toString";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {saveGoodsRestRestriction} from "../../../service/goods_rest_restriction";
import {GoodsRestRestriction} from "../../../types/goodsrestrestriction/intex";
import {getGoodsRestRestrictionValidationSchema} from "./validation";
import map from "lodash/map";
import filter from "lodash/filter";
import {useGoodsAutocomplete} from "./useGoodsAutocomplete";
import {Suggest} from "../../../types";

function getEmpty(): GoodsRestRestriction {
    return {id: 0, chmsoftGoodsId: 0, chmsoftOutletId: 0, chmsoftGoodsName: '', minQuantity: 1};
}

type OwnProps = {
    open: boolean,
    outlets?: Outlet[],
    onClose: () => void
};

export const AddRestrictionDialog = memo<OwnProps>((props) => {
    const {open, outlets, onClose} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>()
    const initialValues = getEmpty();
    const validationSchema = getGoodsRestRestrictionValidationSchema();
    usePreloader(loading);

    const {
        open: autoCompleteOpen,
        options: autoCompleteOptions,
        loading: autoCompleteLoading,
        setOpen: setAutocompleteOpen,
        onKeywordChange: onGoodsKeywordChange
    } = useGoodsAutocomplete();

    const formik = useFormik<GoodsRestRestriction>({
        validateOnChange: false,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values => {
            setLoading(true);
            saveGoodsRestRestriction(values)
                .then(_ => onClose())
                .catch(error => setError(error))
                .finally(() => setLoading(false));
        })
    })

    const handleClose = () => {
        formik.resetForm();
        formik.setValues(initialValues, false);
        setError(undefined);
        onClose();
    }

    const dialogOutlets = filter(outlets, (outlet: Outlet) => outlet.active);

    // noinspection TypeScriptValidateTypes
    return <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>Создание</DialogTitle>
        {!!error && <DialogContentText>
            <ErrorAlert onClose={() => setError(undefined)}>
                {error.message}
            </ErrorAlert>
        </DialogContentText>}
        <DialogContent>

            <Autocomplete<Suggest> fullWidth
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={(option) => option.label}
                open={autoCompleteOpen}
                onChange={(event: any, value: any) => {
                    formik.setValues({
                        ...formik.values,
                        chmsoftGoodsId: value?.value,
                        chmsoftGoodsName: value?.label
                    }, true);
                }}
                onOpen={() => setAutocompleteOpen(true)}
                onClose={() => setAutocompleteOpen(false)}
                options={autoCompleteOptions}
                loading={autoCompleteLoading}
                renderInput={(params) => (
                    <TextField {...params}
                               error={formik.touched.chmsoftGoodsName && Boolean(formik.errors.chmsoftGoodsName)}
                               helperText={formik.touched.chmsoftGoodsName && formik.errors.chmsoftGoodsName}
                               variant="standard"
                               onChange={onGoodsKeywordChange}
                               label="Товар"
                               placeholder={"Начните печатать для поиска"}
                               fullWidth
                               InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                       <React.Fragment>
                                           {autoCompleteLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                           {params.InputProps.endAdornment}
                                       </React.Fragment>
                                   ),
                               }}
                    />
                )}
            />

            <TextField fullWidth select
                       label="Магазин"
                       name="chmsoftOutletId"
                       margin="dense"
                       variant="standard"
                       error={formik.touched.chmsoftOutletId && Boolean(formik.errors.chmsoftOutletId)}
                       helperText={formik.touched.chmsoftOutletId && formik.errors.chmsoftOutletId}
                       onChange={formik.handleChange}
                       value={toString(formik.values.chmsoftOutletId)}>
                {map(dialogOutlets, (outlet: Outlet) => (
                    <MenuItem key={outlet.id} value={outlet.id}>{outlet.outletName}</MenuItem>))}

            </TextField>

            <TextField fullWidth
                       label="Минимальное колличество"
                       name="minQuantity"
                       margin="dense"
                       variant="standard"
                       error={formik.touched.minQuantity && Boolean(formik.errors.minQuantity)}
                       helperText={formik.touched.minQuantity && formik.errors.minQuantity}
                       onChange={formik.handleChange}
                       value={formik.values.minQuantity}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={formik.submitForm}>Сохранить</Button>
            <Button onClick={handleClose}>Отмена</Button>
        </DialogActions>
    </Dialog>;
})