import './App.css';
import React from 'react';
import {Route, Navigate, Routes} from "react-router-dom";
import Login from "./page/Login/Login";
import {Path} from "./constants/path";
import NotFound from "./page/NotFound";
import Dashboard from "./page/Dashboard/Dashboard";
import Settings from "./page/Settings/Settings";
import PostedChecks from "./page/PostedChecks/PostedChecks";
import {useAuthorization} from "./components/AuthorizationProvider";
import isEmpty from "lodash/isEmpty";
import Cashiers from "./page/Cashiers/Cashiers";
import Outlets from "./page/Outlets/Outlets";
import GoodsRestRestrictions from "./page/GoodsRestRestrictions/GoodsRestRestrictions";
import ExciseCashiers from "./page/ExciseCashiers/ExciseCashiers";

function App() {
    const authCtx = useAuthorization();
    const authorized = authCtx !== null && !isEmpty(authCtx.token);

    const protect = (element: React.ReactNode) => {
        if (authorized) return element

        return <Navigate replace to={Path.LOGIN} />
    }

    return (
        <Routes>
            <Route path={Path.LOGIN} element={<Login/>}/>
            <Route path={Path.INDEX} element={protect(<Dashboard/>)}/>
            <Route path={Path.DASHBOARD} element={protect(<Dashboard/>)}/>
            <Route path={Path.CASHIERS} element={protect(<Cashiers/>)}/>
            <Route path={Path.EXCISE_CASHIERS} element={protect(<ExciseCashiers/>)}/>
            <Route path={Path.POSTED_CHECKS} element={protect(<PostedChecks/>)}/>
            <Route path={Path.OUTLETS} element={protect(<Outlets/>)}/>
            <Route path={Path.GOODS_REST} element={protect(<GoodsRestRestrictions/>)}/>
            <Route path={Path.SETTINGS} element={protect(<Settings/>)}/>
            <Route path={"*"} element={protect(<NotFound/>)}/>
        </Routes>
    );
}

export default App;