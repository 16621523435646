import React from "react";
import {IconButton, Stack} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TableCell from "@mui/material/TableCell";
import {OrderBy} from "../../../types";

type OwnProps = {
    field: string
    orderBy?: OrderBy,
    children: string | React.ReactNode,
    onChange: (orderBy: OrderBy) => void

}

const SortedTableCell: React.FC<OwnProps> = (props) => {
    const {children, field, orderBy, onChange} = props;
    let active = orderBy !== undefined && field === orderBy.field;
    const activeDown = active && orderBy !== undefined && orderBy.direction === 'DESC';
    const activeUp = active && !activeDown;

    const handleUp = () => {
        if (activeUp)
            return

        onChange({field: field, direction: 'ASC'});
    }

    const handleDown = () => {
        if (activeDown)
            return

        onChange({field: field, direction: 'DESC'});
    }

    return <TableCell>
        <Stack spacing={8} direction={"row"} alignItems={"center"} justifyContent={"flex-start"}>
            {children}
            {<Stack spacing={1} direction={"row"}
                               alignItems={"center"}
                               sx={{paddingLeft: '1rem'}}>
                <IconButton size={"small"}  color={activeUp ? "secondary" : "default"}
                            onClick={handleUp}>
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton size={"small"} color={activeDown ? "secondary" : "default"}
                            onClick={handleDown}>
                    <ArrowDownwardIcon/>
                </IconButton>
            </Stack>}
        </Stack>
    </TableCell>
}

export default SortedTableCell;