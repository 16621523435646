import React, {ReactNode} from "react";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type OwnProps = {
    children: ReactNode
    onClose: () => void
}

const ErrorAlert = React.memo(({children, onClose}: OwnProps) => {
    return <Alert severity="error"
                  action={<IconButton
                      color="inherit"
                      size="small"
                      onClick={onClose}>
                      <CloseIcon fontSize="inherit"/>
                  </IconButton>}
                  sx={{mb: 2}}>
        {children}
    </Alert>
});

export default ErrorAlert;