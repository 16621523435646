import {PostedCheck} from "../types/postedcheck";
import {axiosInstance} from "../utils/api";
import {PagedSearch, PagedSearchResult} from "../types/pagination";
import {DateTime} from "luxon";
import {OrderBy} from "../types";
import axios from "axios";

export interface PostedChecksSearchCriteria extends PagedSearch {
    since: DateTime,
    till: DateTime,
    status?: | 'SUCCESS' | 'ERROR',
    cashierId?: number
}

export const searchPostedChecks = async (filters: PostedChecksSearchCriteria, orderBy: OrderBy, controller: AbortController): Promise<PagedSearchResult<PostedCheck>> => {
    const {pageNumber, rowsPerPage} = filters;
    let url = `/checkbox/posted-checks/page?pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`;
    if (DateTime.isDateTime(filters.since)) {
        url += '&since=' + filters.since.toISODate()
    }
    if (DateTime.isDateTime(filters.till)) {
        url += '&till=' + filters.till.toISODate();
    }
    if (!!filters.status) {
        url += '&status=' + filters.status;
    }
    if (!!filters.cashierId) {
        url += '&cashierId=' + filters.cashierId;
    }
    if (!!orderBy) {
        url += '&orderBy=' + encodeURIComponent(orderBy.field);
        let direction = 'ASC';
        if (!!orderBy.direction) {
            direction = orderBy.direction;
        }
        url += '&sortDirection=' + direction;
    }

    try {
        const response = await axiosInstance.get(url, {
            signal: controller.signal
        });

        if (response !== undefined) {
            return response.data as PagedSearchResult<PostedCheck>;
        }

        return Promise.resolve({} as PagedSearchResult<PostedCheck>);
    } catch (e) {
        if (axios.isCancel(e)) {
            return Promise.resolve({} as PagedSearchResult<PostedCheck>)
        }
        console.error(e);
        throw e;
    }
}

export const deletePostedCheck = async (checkId: number): Promise<any> => {
    const response = await axiosInstance.delete('/checkbox/posted-checks?id=' + checkId);

    return response.data;
}