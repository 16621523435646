import React, {useEffect, useState} from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DirectionsIcon from '@mui/icons-material/Directions';
import toString from "lodash/toString";
import map from "lodash/map";
import Tooltip from '@mui/material/Tooltip';
import {ButtonGroup, Button, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import {GoodsRestRestriction} from "../../../types/goodsrestrestriction/intex";
import {useFormik} from "formik";
import * as yup from "yup";
import {saveGoodsRestRestrictionMinQuantity} from "../../../service/goods_rest_restriction";

type OwnProps = {
    restriction: GoodsRestRestriction,
    onClose: () => void,
    onSaveComplete: () => void
}

export const EditQty: React.FC<OwnProps> = ({restriction, onClose, onSaveComplete}) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik<{ minQuantity: number }>({
        validateOnChange: false,
        initialValues: {minQuantity: restriction.minQuantity},
        validationSchema: yup.object({minQuantity: yup.number().min(1).required('Введите количество')}),
        onSubmit: (values => {
            setLoading(true);
            saveGoodsRestRestrictionMinQuantity(restriction.id, values.minQuantity)
                .then(_ => onClose())
                .catch(error => console.error(error))
                .finally(() => {
                    setLoading(false);
                    onSaveComplete();
                });
        })
    });

    const handleCancel = (event: any) => {
        formik.resetForm();
        onClose();
    }


    return <Stack justifyContent={"flex-end"} spacing={1} direction={"row"}>
        <TextField name={"minQuantity"}
                   inputRef={(input) => {
                       if (input != null) {
                           input.focus();
                       }
                   }}
                   margin="dense"
                   variant="standard"
                   value={formik.values.minQuantity}
                   error={formik.touched.minQuantity && Boolean(formik.errors.minQuantity)}
                   helperText={formik.touched.minQuantity && formik.errors.minQuantity}
                   onChange={formik.handleChange}
            // value={toString(formik.values.chmsoftOutletId)}
        />
        <ButtonGroup variant="text" aria-label="outlined button group">
            <Tooltip title="Сохранить" arrow>
                <Button onClick={formik.submitForm}
                        disabled={loading}
                        color={"success"}><SaveIcon/></Button>
            </Tooltip>
            <Tooltip title="Отмена" arrow>
                <Button color={"inherit"}
                        disabled={loading}
                        onClick={handleCancel}><CancelIcon/></Button>
            </Tooltip>
        </ButtonGroup>
    </Stack>
}