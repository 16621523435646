import React, {FormEvent, useCallback, useState} from "react";
import StoreIcon from "@mui/icons-material/Store";
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Stack
} from "@mui/material";
import Page from "../template/Page";
import Filters from "../template/Filters";
import {FilterActive} from "../filters/FilterActive";
import {OutletsSearchCriteria} from "../../service/outlets";
import {useOutlets} from "./useOutlets";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import {Outlet} from "../../types/outlet";
import ErrorAlert from "../../components/ErrorAlert";
import {errorMessage} from "../../constants/text";
import {CashierSelect} from "./CashierSelect";
import {CashiersSearchCriteria} from "../../service/cashiers";
import {useCashiers} from "../Cashiers/useCashiers";
import Typography from "@mui/material/Typography";
import {TelegramChatId} from "./TelegramChatId";
import Content from "../template/content/Content";
import NothingFound from "../../components/NothingFound";
import {useExciseCashiers} from "../ExciseCashiers/useExciseCashiers";

const checkboxCashiersCriteria: CashiersSearchCriteria = {onlyActive: false};

const Outlets = () => {
    const [filters, setFilters] = useState<OutletsSearchCriteria>({onlyActive: true})
    const {cashiers} = useCashiers(checkboxCashiersCriteria);
    const exciseCashiersRes = useExciseCashiers(filters);
    const handleActiveFilterChange = useCallback((_: FormEvent<HTMLInputElement>) => {
        setFilters({onlyActive: !filters.onlyActive});
    }, [filters, setFilters]);

    const {error, outlets, clearError, linkToCheckboxCashier, linkToCheckboxExciseCashier, saveTelegramChatId} = useOutlets(filters);

    return <Page title="Магазины" icon={<StoreIcon/>}>
        <Stack direction="column" spacing={2}>
            <Filters>
                <FilterActive value={filters.onlyActive} onChange={handleActiveFilterChange}/>
            </Filters>
            {!!error && <ErrorAlert onClose={clearError}>
                {error?.message || errorMessage.defaultMessage}
            </ErrorAlert>}

            <Content>
                {isEmpty(outlets) && <NothingFound/>}

                <Grid container justifyContent={"flex-start"} spacing={1}>
                    {map(outlets, (outlet: Outlet) => {
                        return <Grid key={outlet.id} item md={4} sm={12} sx={{width: '100%'}}>
                            <Card sx={{width: '100%'}} className={"disable-text-selection"}>
                                <CardHeader title={outlet.outletName}
                                            subheader={<Subheader outlet={outlet}/>}/>
                                <CardContent>
                                    <Typography color={"textSecondary"} sx={{pb: 0.5}}>Checkbox Кассир</Typography>
                                    <CashierSelect
                                        outlet={outlet}
                                        cashier={outlet.checkboxCashier}
                                        options={cashiers}
                                        onSelect={linkToCheckboxCashier}/>
                                    <Typography color={"textSecondary"} sx={{pb: 0.5, pt: 1}}>Кассир АКЦИЗ</Typography>
                                    <CashierSelect
                                        outlet={outlet}
                                        cashier={outlet.checkboxExciseCashier}
                                        options={exciseCashiersRes.cashiers}
                                        onSelect={linkToCheckboxExciseCashier}/>
                                    <Typography color={"textSecondary"} sx={{pb: 0.5, pt: 1}}>Телеграм Чат ID</Typography>
                                    <TelegramChatId outlet={outlet} onChange={saveTelegramChatId}/>

                                </CardContent>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </Content>
        </Stack>
    </Page>
};

const Subheader: React.FC<{ outlet: Outlet }> = ({outlet}) => (
        <Typography color={outlet.active ? "inherit" : "error"}>
            {outlet.active ? "Активный" : "Не активный"}
        </Typography>)

export default Outlets;