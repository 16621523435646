import {CheckboxCashier} from "../../../types/employee";
import {memo, useCallback, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "../../../components/ConfirmationDialog";
import usePreloader from "../../../hooks/usePreloader";
import {deleteCheckboxCashier} from "../../../service/cashiers";
import {Button} from "@mui/material";

type OwnProps = {
    cashier: CheckboxCashier,
    onClose: () => void
};

export const DeleteCashier = memo<OwnProps>(({cashier, onClose}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    usePreloader(loading);

    const handleDelete = useCallback(() => {
        setLoading(true);
        deleteCheckboxCashier(cashier.id)
            .then(_ => onClose())
            .catch(error => alert(error.message || 'Во время удаления Кассира произошла ошибка'))
            .finally(() => setLoading(false));

    }, [cashier.id, onClose, setLoading])



    return <>
        <Button size={"medium"} startIcon={<DeleteIcon fontSize="inherit" color={"error"}/>}
                    onClick={useCallback(() => setOpen(true), [setOpen])}>
            Удалить
        </Button>
        <ConfirmDialog open={open}
                       onAccept={handleDelete}
                       onReject={useCallback(() => setOpen(false), [setOpen])}>
            Вы действительно хотите удалить Кассира {cashier.name}?
        </ConfirmDialog>

    </>
})