import * as yup from "yup";

export const getLoginValidationSchema = () => {
    return yup.object({
        email: yup
            .string()
            .email('Не корректный email')
            .required('Введите email'),
        password: yup
            .string()
            .min(6, 'Не корректный пароль')
            .required('Введите пароль'),
    });
}