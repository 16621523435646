import {deletePostedCheck, PostedChecksSearchCriteria, searchPostedChecks} from "../../service/checks";
import {useCallback, useEffect, useState} from "react";
import {PostedCheck} from "../../types/postedcheck";
import usePreloader from "../../hooks/usePreloader";
import {getAxiosCancellationFunction} from "../../utils/api";
import {PagedSearchResult} from "../../types/pagination";
import {OrderBy} from "../../types";

export function usePostedChecks(filters: PostedChecksSearchCriteria, orderBy: OrderBy) {
    const [version, setVersion] = useState<number>(0);
    const {pageNumber, rowsPerPage} = filters;
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [page, setPage] = useState<PagedSearchResult<PostedCheck>>({
        pageNumber,
        pageSize: rowsPerPage,
        results: [],
        totalPages: 1,
        totalResults: 0
    });

    usePreloader(loading);

    useEffect(() => {
        let mounted = true;
        const abCtrl = new AbortController();
        setLoading(true);

        searchPostedChecks(filters, orderBy, abCtrl)
            .then(value => {
                if (mounted) setPage(value);
            }).catch(error => {
            if (mounted) setError(error);
        }).finally(() => {
            if (mounted) setLoading(false)
        })

        return getAxiosCancellationFunction(mounted, abCtrl)

    }, [version, filters, orderBy]);

    const deleteCheck = useCallback((check: PostedCheck) => {
        setLoading(true);

        deletePostedCheck(check.id)
            .then(r => setVersion(version + 1))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }, [setVersion, version]);

    return {
        loading,
        error,
        reload: useCallback(() => setVersion(version+1), [setVersion, version]),
        deleteCheck: deleteCheck,
        clearError: useCallback(() => setError(undefined), [setError]),
        page
    }
}